
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ArticleCtaLeafBannerInterface } from '~/types/articlePage';

export default defineComponent({
    name: 'ArticleCtaLeafBanner',
    props: {
        blok: {
            type: Object as PropType<ArticleCtaLeafBannerInterface>,
            required: true,
        },
    },
});
